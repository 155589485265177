<template>
  <v-card class="ma-0 pa-0 d-block">
    <div>
      <apexchart
        type="heatmap"
        :options="chartOptions"
        :series="series"
        height="400"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
/**
 * ColumnChart
 * @description Apex heatmap chart
 *
 */

export default {
  name: "ReportsHeatMapChart",
  props: {
    /**
     * @type {{name: string,type: string,dateLabel: string,title: string,values: {categories: array,series: array,}}}
     */
    chart: {
      required: true,
      type: Object,
    },
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "heatmap",
          height: "400",
        },
        colors: this.chart.values.colors
          ? this.chart.values.colors
          : ["#7e04b7"],
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.chart.values.x_categories,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              fontSize: "8px",
            },
          },
        },
      };
    },
    series: function () {
      let result = this.chart.values.series.map((element) => {
        return {
          name: element.name,
          data: element.data.map((item) => item.y),
        };
      });
      return result;
    },
  },
};
</script>
