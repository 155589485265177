<template>
  <div v-if="isDataLoaded">
    <v-card v-for="(chart, i) in serverData.charts" :key="i">
      <ReportsColumnStackedChart
        v-if="chart.type === 'columnStacked100'"
        :chart="chart"
      ></ReportsColumnStackedChart>
      <ReportsColumnGroupChart
        v-if="chart.type === 'groupColumnChart'"
        :chart="chart"
      ></ReportsColumnGroupChart>
      <reports-column-basic-chart
        v-if="chart.type === 'columnSimple'"
        :chart="chart"
      ></reports-column-basic-chart>
      <reports-column-distributed-chart
        v-if="chart.type === 'column'"
        :chart="chart"
      ></reports-column-distributed-chart>
      <reports-column-two-axis-chart
        v-if="chart.type === 'column_2_axis'"
        :chart="chart"
      ></reports-column-two-axis-chart>
      <reports-mixed-column-line-chart
        v-if="chart.type === 'mixedColumnLineChart'"
        :chart="chart"
      ></reports-mixed-column-line-chart>
      <reports-heat-map-chart
        v-if="chart.type === 'heatmap'"
        :chart="chart"
      ></reports-heat-map-chart>
    </v-card>
  </div>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>
<script>
import ReportsColumnBasicChart from "@/own/components/reports/ReportsColumnBasicChart.vue";
import ReportsColumnStackedChart from "@/own/components/reports/ReportsColumnStackedChart.vue";
import ReportsColumnGroupChart from "@/own/components/reports/ReportsColumnGroupChart.vue";
import ReportsColumnDistributedChart from "@/own/components/reports/ReportsColumnDistributedChart.vue";
import ReportsMixedColumnLineChart from "@/own/components/reports/ReportsMixedColumnLineChart.vue";
import ReportsHeatMapChart from "@/own/components/reports/ReportsHeatMapChart.vue";
import ReportsColumnTwoAxisChart from "@/own/components/reports/ReportsColumnTwoAxisChart.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
export default {
  name: "ReportsChartSelector",
  props: ["item", "filterData"],
  components: {
    ReportsColumnBasicChart,
    ReportsColumnDistributedChart,
    ReportsColumnStackedChart,
    ReportsMixedColumnLineChart,
    ReportsColumnGroupChart,
    ReportsHeatMapChart,
    ReportsColumnTwoAxisChart,
  },
  data: () => ({
    /**
     * store fetched data
     * @description store fetched data from server
     * @type {{charts:[{name: string,type: string,dateLabel: string,title: string,values: {categories: array,series: array,}}],success: boolean}}
     */
    serverData: null,
  }),
  mounted() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = { filters: this.filterData };
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data;
          if (this.serverData.charts.length === 0) {
            Swal.fire({
              title: "Warning",
              text: `No Data Found`,
              icon: "warning",
              showConfirmButton: true,
              timer: 2500,
            });
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
