<template>
  <v-card class="ma-0 pa-0 d-block">
    <div>
      <apexchart
        type="line"
        :options="chartOptions"
        :series="series"
        height="400"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
/**
 * ColumnChart
 * @description Apex bar chart
 *
 */

export default {
  name: "ReportsMixedColumnLineChart",
  props: {
    /**
     * @type {{name: string,type: string,dateLabel: string,title: string,values: {categories: array,series: array,}}}
     */
    chart: {
      required: true,
      type: Object,
    },
  },
  computed: {
    colors: function () {
      if (this.chart.name === "reportFulfilledVsShipped") {
        if (this.chart.values.colors && this.chart.values.series) {
          const shippedValue = this.chart.values.series[0];
          if (shippedValue > 80)
            return [
              this.chart.values.colors[0],
              this.chart.values.colors[4],
              this.chart.values.colors[3],
            ];
          else if (shippedValue < 50) {
            return [
              this.chart.values.colors[2],
              this.chart.values.colors[4],
              this.chart.values.colors[3],
            ];
          } else {
            return [
              this.chart.values.colors[1],
              this.chart.values.colors[4],
              this.chart.values.colors[3],
            ];
          }
        } else return ["#7e04b7"];
      } else {
        return this.chart.values.colors
          ? this.chart.values.colors
          : ["#7e04b7"];
      }
    },
    chartOptions: function () {
      return {
        chart: {
          type: "line",
          height: "400",
        },
        stroke: {
          width: [5, 2],
          curve: "straight",
          lineCap: "round",
          colors: this.colors,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "right",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1],
          position: "top",
          // formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          formatter: function (value, { seriesIndex }) {
            if (seriesIndex === 0) {
              return value + "%";
            } else {
              return value;
            }
          },
          dropShadow: {
            enabled: true,
            left: 1,
            top: 2,
            opacity: 0.3,
          },
        },
        title: {
          text: this.chart.title,
          align: "center",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "22px",
            fontWeight: 800,
            fontFamily: undefined,
            color: "#263238",
          },
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            columnWidth: "55%",
            distributed: false,
            borderRadius: 2,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        fill: {
          // opacity: 1,
        },
        yaxis: [
          {
            title: {
              text:
                this.chart.name === "reportFulfilledVsShipped"
                  ? "Shipped percentage"
                  : "Delivered percentage",
              style: {
                color: undefined,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
          },
          {
            show: this.chart.name === "reportFulfilledVsShipped" ? true : false,
            opposite: true,
            showAlways: true,
            seriesName: "Warehouse",
            title: {
              text: "Warehouse",
              style: {
                color: undefined,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          {
            opposite: true,
            showAlways: true,
            title: {
              text: "Fulfilled order count",
              style: {
                color: undefined,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 800,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
        ],
        xaxis: {
          categories: this.chart.values.categories,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      };
    },
    series: function () {
      return this.chart.values.series;
    },
  },
};
</script>
