<template>
  <v-card class="ma-0 pa-0 d-block">
    <div>
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        height="400"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
/**
 * ColumnChart
 * @description Apex bar chart
 *
 */

export default {
  name: "ReportsColumnDistributedChart",
  props: {
    /**
     * @type {{name: string,type: string,dateLabel: string,title: string,values: {categories: array,series: array,}}}
     */
    chart: {
      required: true,
      type: Object,
    },
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          height: "400",
        },
        colors: this.chart.values.colors
          ? this.chart.values.colors
          : ["#7e04b7"],
        plotOptions: {
          bar: {
            columnWidth: "85%",
            distributed: true,
            borderRadius: 8,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.chart.values.categories,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              // colors: this.serverData.values.color1,
              fontSize: "8px",
            },
          },
        },
      };
    },
    series: function () {
      return this.chart.values.series;
    },
  },
};
</script>
