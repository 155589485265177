<template>
  <div v-if="isDataLoaded" class="custom-content-height">
    <div class="mb-10 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-sm-9 col-md-9"
        :table_properties="table_properties"
      ></datatable-detail>
      <div v-if="hasExport" class="pr-4">
        <button @click="handleExport" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Export
        </button>
      </div>
    </div>
    <!-- 
      filters of report
     -->
    <Filters
      ref="filter"
      :submitFilterValues="submitFilterValues"
      :resetFilterValues="resetFilterValues"
      :filters="filters"
      :setTableFiltersValues="setTableFiltersValues"
    ></Filters>
    <hr />
    <ReportsChartSelector
      :item="item_data"
      v-if="item"
      :filter-data="filters_for_charts"
    >
    </ReportsChartSelector>
  </div>
</template>

<script>
/**
 * ReportCharts
 */

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportsChartSelector from "@/own/components/reports/ReportsChartSelector";
import Filters from "@/own/components/filter/Filters.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { EXPORT_REPORT_AGENT_DATA } from "@/core/services/store/reportAgent.module";

export default {
  name: "ReportCharts",
  components: {
    Filters,
    ReportsChartSelector,
    DatatableDetail,
  },
  props: {
    /**
     * @description urls to fetch chart data
     * @type {{data_url: string, show_url: string}}
     */
    urls: {
      type: Object,
      default: () => ({ data_url: String, show_url: String }),
      required: true,
    },
    /**
     * @description for setting filters mandatory status
     * @values true, false, ['some_filter_name']
     * @type {boolean, [{title: string, name: string, valueFieldName: string}]}
     */
    mandatoryFilter: {
      type: [Boolean, Array],
      default: false,
    },
  },
  data: () => ({
    /**
     * Chart related data
     * @type {{url: string}}
     */
    item_data: null,

    /**
     * dynamic filter data
     * @description store filter data
     * @type {Object[]}
     */
    filters_data: [],

    /**
     * filter data for fetching charts
     * @description value is not changing until filter submint btn clicked
     * @type {Object[]}
     */
    filters_for_charts: null,

    /**
     * page title data
     * @description data for page title and subtitle
     * @type {{title:string, description: string}}
     */
    properties_data: null,

    /**
     * store fetched data
     * @description store fetched data from server
     * @type {{"properties": {"title": string,"description": string},"filters": [{"name": string,"title": {"en": string},"type": string}]}}
     */
    serverData: null,
  }),
  computed: {
    /**
     * return filters if data loaded from server else null
     */
    filters: function () {
      return this.serverData.filters;
    },

    /**
     * checking if data loaded or not
     */
    isDataLoaded: function () {
      return !!this.serverData;
    },

    /**
     * return chart related data
     */
    item: function () {
      return this.item_data;
    },

    /**
     * return page title data
     */
    table_properties: function () {
      return this.serverData.properties;
    },

    /**
     * return export show flag
     */
    hasExport: function () {
      return this.serverData.export;
    },
  },
  beforeMount() {
    /**
     * fetch data from server if it's not fetched
     */
    this.serverData ? true : this.loadDataFromServer();
  },
  methods: {
    handleExport() {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          filters: this.filters_data,
        };
        this.$store
          .dispatch(EXPORT_REPORT_AGENT_DATA, {
            dataUrl: this.urls.data_url,
            payload,
          })
          .then(() => {
            const link = document.createElement("a");
            link.href = this.$store.getters.getREPORTAGENTExportUrl;
            link.download = "Export.csv";
            link.click();
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.export.toggleModal();
            link.remove();
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },

    isAnyFilterSelected() {
      return this.filters_data.length > 0;
    },
    /**
     * set chart data
     */
    loadChartFromServer() {
      this.item_data = {
        url: this.urls.show_url,
      };
    },

    /**
     * load chart data from server
     */
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post(this.urls.data_url)
        .then((response) => {
          this.serverData = response.data.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    /**
     * change pageLoader state
     * @param {boolean} state
     */
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },

    /**
     * reset filter data to initial
     */
    resetFilterValues() {
      this.filters_data = [];
      this.item_data = null;
      this.$store.commit(SET_PAGE_LOADING, false);
    },

    /**
     * validate requirement of filters
     * @returns Boolean
     */
    validateFilterValues() {
      let is_valid = true;
      // if mandatoryFilter array then checks are all filters located in array are selected
      if (Array.isArray(this.mandatoryFilter)) {
        this.mandatoryFilter.forEach((filter) => {
          let is_exist = false;
          this.filters_data.forEach((data) => {
            if (data.name === filter.name + filter.valueFieldName) {
              if (
                data.value !== "" &&
                data.value !== null &&
                data.value !== undefined
              ) {
                is_exist = true;
              }
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      // if mandatoryFilter is true then check all filter values are selected
      else if (this.mandatoryFilter) {
        this.filters.forEach((filter) => {
          let is_exist = false;
          this.filters_data.forEach((data) => {
            if (data.name === filter.name) {
              is_exist = true;
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      return is_valid;
    },

    /**
     * set filter data
     */
    setTableFiltersValues(val) {
      this.filters_data = val;
    },

    /**
     * validate filters and set chart filter then and reload data from server
     */
    submitFilterValues() {
      if (this.filters_data && this.validateFilterValues()) {
        this.filters_for_charts = this.filters_data;
        this.loadChartFromServer();
      } else {
        if (Array.isArray(this.mandatoryFilter)) {
          let message = this.mandatoryFilter
            .map((filter) => filter.title)
            .join(",");
          Swal.fire(
            "Warning!",
            `Select ${message} filter${
              this.mandatoryFilter.length > 1 ? "s" : ""
            }!`,
            "warning"
          );
        } else if (this.mandatoryFilter) {
          Swal.fire("Warning!", "Select all filters!", "warning");
        }
      }
    },
  },
};
</script>
